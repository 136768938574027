
	import { Component, Vue, Prop } from 'vue-property-decorator';
	import IconCircleNotch from '@pixcap/ui-library/components/Icons/IconCircleNotch.vue';
	import Icon from '@pixcap/ui-library/common/Icon.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import CaptionText from '@pixcap/ui-library/components/Typography/CaptionText.vue';
	import { TextSize, TextWeight } from '@pixcap/ui-library/common/typography/typography';

	type ButtonColor = 'primary' | 'secondary' | 'neutral' | 'white';
	type ButtonVariant = 'contain' | 'outline' | 'text';
	enum ButtonSize {
		xsmall = 'xsmall',
		small = 'small',
		medium = 'medium',
		large = 'large',
		xlarge = 'xlarge',
	}
	type ButtonTypography = 'BodyText' | 'CaptionText';

	@Component({
		name: 'Button',
		components: {
			IconCircleNotch,
			Icon,
			CaptionText,
			BodyText,
		},
	})
	export default class Button extends Vue {
		@Prop({ default: 'contain' }) readonly variant: ButtonVariant;
		@Prop({ default: 'primary' }) readonly color: ButtonColor;
		@Prop({ default: ButtonSize.medium }) readonly btnSize: ButtonSize;
		@Prop({ default: 'small' }) readonly textSize: TextSize;
		@Prop({ default: 'semibold' }) readonly textWeight: TextWeight;
		@Prop({ default: 'BodyText' }) readonly typographyComponent: ButtonTypography;
		@Prop() readonly icon: string; // Icon name, capitalized
		@Prop() readonly hasRightIcon: boolean; // Icon name, capitalized
		@Prop() readonly iconSize: number; // custom icon size
		@Prop() readonly className: string | undefined;
		@Prop() readonly isLoading: boolean; // Icon name, capitalized
		@Prop() readonly disabled: boolean;

		btnIconSizes = {
			[ButtonSize.large]: 22,
			[ButtonSize.medium]: 20,
			[ButtonSize.small]: 20,
			[ButtonSize.xsmall]: 16,
		};

		get isDisabled() {
			return this.disabled || this.isLoading;
		}

		get btnIconSize() {
			return this.iconSize || this.btnIconSizes[this.btnSize];
		}

		get computedClassName() {
			return [
				`btn--variant--${this.variant}`,
				`btn--color--${this.color}`,
				`btn--size--${this.btnSize}`,
				this.className ? this.className : '',
				this.isLoading ? 'btn-loading' : '',
			];
		}
	}
